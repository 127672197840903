import React, { PureComponent } from 'react';
// import Carousel from 'react-elastic-carousel';
import Carousel from './carousel';
import CarouselItem from './CarouselItem';

class PostCarousel extends PureComponent {
  constructor(props) {
    try {
      super(props);
      this.state = {
        fileList: [],
        showDeleteOption: false,
        deletedItems: [],
        videoThumbnail: [],
      };
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate() {
    try {
      if (this.props.items && this.props.items.length > 0) {
        this.setState({
          fileList: this.props.items,
        });
      }
      if (this.props.videoThumbnail && this.props.videoThumbnail.length > 0) {
        this.setState({
          videoThumbnail: this.props.videoThumbnail,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  deleteOption() {
    return this.state.showDeleteOption ? (
      <button>
        <img src="/img/close.svg" />
      </button>
    ) : (
      ''
    );
  }
  setdeleteOption(val) {
    try {
      this.setState({
        showDeleteOption: val,
      });
    } catch (err) {
      console.log(err);
    }
  }
  componentDidMount() {
    try {
      if (this.props.items && this.props.items.length > 0) {
        this.setState({
          fileList: this.props.items,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async deleteItem(item, i) {
    try {
      let fileList = [...this.state.fileList];
      let deletedItems = [...this.state.deletedItems];

      let ele = fileList.find((obj) => obj.id === item);

      let index = fileList.indexOf(ele);
      fileList.splice(index, 1);
      deletedItems.push(item);

      await this.setState(
        {
          fileList: fileList,
          deletedItems: deletedItems,
          isVideoUploaded: false,
        },
        () => {
          this.props.onDeleteItem(item, i);
        },
      );

      if (this.state.fileList.length > 0) {
        // let imageExistOrNot = this.state.fileList.forEach((element) => {
        //   Object.values(element).includes('image');
        // });

        let imageExistOrNot = this.state.fileList?.some(
          (media) => media?.type === 'image',
        );

        let videoExistOrNot = this.state.fileList?.some(
          (media) => media?.type === 'video',
        );

        if (videoExistOrNot) {
          this.props.toggleVideo();
        } else if (imageExistOrNot) {
          this.props.toggleImage();
        } else if (!imageExistOrNot && !videoExistOrNot) {
          this.props.toggleImageVideo();
        }
      } else if (this.state.fileList.length === 0) {
        this.props.toggleImageVideo();
      }
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <>
        {this.state.fileList.length > 0 ? (
          <Carousel isSmall>
            {this.state.fileList && this.state.fileList.length > 0
              ? this.state.fileList.map((item, index) => {
                  return (
                    <CarouselItem
                      onDeleteItem={(item) => this.deleteItem(item, index)}
                      item={item}
                      key={item.id}
                      isVideoUploaded={this.state.isVideoUploaded}
                      thumbnail={this.props.thumbnail}
                      removeThumbnail={this.props.removeThumbnail}
                      files={this.props.files}
                      handleImageUpload={this.props.handleImageUpload}
                      mode={this.props.mode}
                      index={index}
                      attachments={this.props.attachments}
                      updateThumbnailError={this.props.updateThumbnailError}
                      thumbnailError={this.props.thumbnailError}
                    />
                  );
                })
              : ''}
          </Carousel>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default React.memo(PostCarousel);
