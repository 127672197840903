import React, { PureComponent } from 'react';
import TileGallery from '../gallery/TileGallery';
import VerticalTileGallery from '../gallery/VerticalTileGallery';
import SinglePortait from '../gallery/SinglePortait';
class Gallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { gallery, galleryType, top } = this.props;

    return (
      <>
        {galleryType === 'tile' ? (
          <TileGallery
            items={gallery}
            top={top}
            onShowBigImg={(e, obj) => this.props.onShowBigImg(gallery, obj)}
          />
        ) : galleryType === 'vertical tile' ? (
          <VerticalTileGallery
            items={gallery}
            onShowBigImg={(e, obj) => this.props.onShowBigImg(gallery, obj)}
          />
        ) : galleryType === 'single portait' ? (
          <SinglePortait
            items={gallery}
            onShowBigImg={(e, obj) => this.props.onShowBigImg(gallery, obj)}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}
export default React.memo(Gallery);
