import React, { PureComponent } from 'react';
import renderHTML from 'react-render-html';
import PostHeader from './PostHeader';
import AttachmentList from './AttachmentList';
import Slider from './Slider';
import CreatePost from '../CreatePost';
import Gallery from './Gallery';
import ActionButtons from '../postaction/ActionButtons';
import CommentBox from '../comments/CommentBox';
import { API } from '../api/API';
import { Global } from '../api/Global';
import ShortInfo from '../postaction/ShortInfo';
import PostPoll from '../poll/PostPoll';
import ConfirmBox from '../ui/ConfirmBox';
import PollResult from '../poll/PollResult';
import { UncontrolledAlert } from 'reactstrap';
import ReactPlayer from 'react-player';

class SinglePost extends PureComponent {
  constructor(props) {
    try {
      super(props);
      this.postRef = React.createRef();
      this.contRef = React.createRef();

      this.state = {
        sliderIndex: 0,
        creatorId: null,
        showSlider: false,
        showEditPostBox: false,
        showCommentBox: false,
        mode: 'edit',
        comments: [],
        totalComments: 0,
        confirmBoxStatus: false,
        hasImgOrVideoExist: false,
        poll: null,
        publishAt: null,
        likedByUser: 0,
        totalLikes: 0,
        isSharedWithAdmin: null,
        isSharedWithAll: null,
        isSharedWithLearner: null,
        onlySharedWith: null,
        confirmMessage: null,
        extractContent: false,
        hasReadMore: false,
        pinnedBy: null,
        top: null,
        id: null,
        isShowMore: false,
        adBottomDatas: null,
        days: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ],
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        videoThumbnail: [],
        attachments: [],
      };
    } catch (error) {
      console.log(error);
    }
  }
  convertDate = (val) => {
    let obj = new Date(val);
    // Thursday, 17 April at 9:15am
    let day = this.state.days[obj.getDay()];
    let date = obj.getDate();
    let month = this.state.months[obj.getMonth()];
    //  let hours = obj.getHours() > 12 ? obj.getHours() - 12 : obj.getHours();
    let hours =
      obj.getHours() === 12
        ? 12
        : obj.getHours() === 0
        ? 12
        : obj.getHours() < 12
        ? obj.getHours()
        : obj.getHours() > 12
        ? obj.getHours() - 12
        : '';
    let minutes = obj.getMinutes();
    let ampm =
      obj.getHours() === 12
        ? 'am'
        : obj.getHours() === 0
        ? 'pm'
        : obj.getHours() < 12
        ? 'am'
        : obj.getHours() > 12
        ? 'pm'
        : '';
    return (
      <span>
        <span>{day},</span> <span> {date} </span> <span> {month} </span>{' '}
        <span> at </span>
        <span>
          {hours} : {this.showMinutes(minutes)} {ampm}
        </span>{' '}
      </span>
    );
  };
  showMinutes(val) {
    try {
      if (val < 10) {
        return '0' + val;
      } else {
        return val;
      }
    } catch (error) {
      console.error(error);
    }
  }
  propsToSate() {
    try {
      this.setState({
        totalComments: this.props.totalComments,
        creatorId: this.props.creatorId,
        id: this.props.id ? this.props.id : null,
        isPinned: this.props?.isPinned ? this.props.isPinned : false,
        poll: this.props.poll,
        likedByUser: this.props.likedByUser,
        totalLikes: this.props.totalLikes,
        img: this.props.img,
        updatedAt: this.props.updatedAt,
        publishAt: this.props.publishAt ? this.props.publishAt : null,
        from: this.props.from,
        title: this.props.title,
        content: this.props.content,
        galleryType: this.props.galleryType,
        gallery: this.props.gallery,
        attachments: this.props.attachments,
        newsFeedID: this.props.newsFeedID,
        creatorName: this.props.creatorName,
        isSchedule: this.props.isSchedule,
        isPollVoted: this.props.isPollVoted,
        isCommentable: this.props.isCommentable,
        pinnedBy: this.props.pinnedBy,
        isExpanded: false,
        confirmMessage: '',
        contHeight: 0,
        comments: [],
        medias: this.props.medias,
        meta: this.props.meta,
        maxWord: 150,
        isSharedWithAdmin: this.props.isSharedWithAdmin
          ? this.props.isSharedWithAdmin
          : null,
        isSharedWithAll: this.props.isSharedWithAll
          ? this.props.isSharedWithAll
          : null,
        isSharedWithLearner: this.props.isSharedWithLearner
          ? this.props.isSharedWithLearner
          : null,
        onlySharedWith: this.props.onlySharedWith
          ? this.props.onlySharedWith
          : null,
        adBottomDatas: this.props.adBottomDatas,
        videoThumbnail: this.filterVideoThumbnail(this.props.gallery),
      });
    } catch (error) {
      console.log(error);
    }
  }

  filterVideoThumbnail(medias) {
    return medias
      .map((media, index) => {
        if (media.videoThumbnailUrl) {
          return {
            id: media.id,
            index,
            url: media.videoThumbnailUrl,
          };
        }
        return null; // Explicitly handle cases without a videoThumbnailUrl
      })
      .filter((item) => item !== null); // Remove null entries
  }

  // filterVideoThumbnail(medias) {
  //   const data = medias
  //     .filter((media) => media.videoThumbnailUrl)
  //     .map((media, index) => ({
  //       id: media.id,
  //       index,
  //       url: media.videoThumbnailUrl,
  //     }));

  //   return data;
  // }

  componentDidMount() {
    this.propsToSate();

    setTimeout(() => {
      //console.log(this.contRef.current.clientHeight);
      if (this.contRef.current) {
        this.setState({
          contHeight: this.contRef.current.clientHeight,
          hasReadMore: this.contRef.current.clientHeight > 115 ? true : false,
        });
      }
    }, 500);

    window.addEventListener('scroll', () => {
      if (this.postRef.current) {
        this.setState({
          top: this.postRef.current.getBoundingClientRect().top,
        });
      }
    });
  }
  async componentDidUpdate(prevProps) {
    await this.setState({
      totalLikes: this.props.totalLikes,
      totalComments: this.props.totalComments,
      medias: this.props.combined,
      attachments: this.props.attachments,
    });
    if (this.props.gallery !== prevProps.gallery) {
      const videoThumbnail = this.filterVideoThumbnail(this.props.gallery);
      if (
        JSON.stringify(videoThumbnail) !==
        JSON.stringify(this.state.videoThumbnail)
      ) {
        this.setState({ videoThumbnail });
      }
    }
    // Mention Target Blank on anchors
    if (this.contRef.current) {
      let cont = this.contRef.current.querySelectorAll('a');
      if (cont.length > 0) {
        cont.forEach((item) => {
          item.setAttribute('target', '_blank');
        });
      }
    }
  }
  setCommentStatus = () => {
    try {
      let status = !this.state.showCommentBox;

      let payload = {
        id: this.props.id,
      };
      this.props.onLoading(true);
      API.getComments(payload)
        .catch((error) => {
          console.log(error);
          this.props.onLoading(false);
        })
        .then((response) => {
          this.setState(
            (prevState) => {
              return {
                comments: response.data.data,
                meta: response.data.meta,
                showCommentBox: status,
              };
            },
            () => {
              this.props.onLoading(false);
            },
          );
        });
    } catch (error) {
      console.log(error);
    }
  };
  closeSlider() {
    try {
      this.setState({
        sliderIndex: 0,
        showSlider: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  showLargeImg(gallery, obj) {
    try {
      let ele = gallery.find((item) => item.src === obj.src);
      if (ele) {
        let objIndex = gallery.indexOf(ele);
        if (objIndex >= 0) {
          this.setState({
            sliderIndex: objIndex,
            showSlider: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  showEditBox() {
    try {
      this.setState({
        showEditPostBox: true,
        sliderIndex: 0,
        showSlider: false,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async updatePost(val) {
    try {
      let obj = Global.createSingleNewsFeedObj(val);

      await this.setState(
        {
          showEditPostBox: false,
          commentStatus: false,
          content: obj.content,
          newsFeedClientId: obj.newsFeedClientId,
          title: obj.title,
          creatorId: obj.creatorId,
          gallery: obj.gallery,
          galleryType: obj.galleryType,
          updatedAt: obj.updatedAt,
          attachments: obj.attachments,
          isSharedWithAll: obj.isSharedWithAll ? obj.isSharedWithAll : null,
          isSharedWithAdmin: obj.isSharedWithAdmin
            ? obj.isSharedWithAdmin
            : null,
          isSharedWithLearner: obj.isSharedWithLearner
            ? obj.isSharedWithLearner
            : null,
          onlySharedWith: obj.hasOwnProperty('onlySharedWith')
            ? obj.onlySharedWith
            : null,
        },
        () => {
          this.props.onUpdateItem(obj);
          this.props.onLoading(false);
        },
      );
    } catch (error) {
      console.log(error);
    }
  }
  changeMode(val) {
    try {
      this.setState({
        showEditPostBox: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async addComment(data) {
    try {
      await this.setState((prevState) => {
        return { totalComments: prevState.totalComments + 1 };
      });
      this.props.onLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  setConfirmBoxStatus(obj) {
    try {
      this.setState({
        confirmBoxStatus: obj.status,
        confirmMessage: obj.msg,
      });
    } catch (error) {
      console.log(error);
    }
  }
  deletePoll() {
    try {
      API.deletePoll({ id: this.state.poll._id })
        .catch((error) => {
          console.log(error);
          this.setState({
            confirmBoxStatus: false,
          });
        })
        .then((response) => {
          this.setState({
            showEditPostBox: false,
            confirmBoxStatus: false,
            poll: null,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  editedPoll(val) {
    try {
      let poll = Object.assign([], this.state.poll);
      poll.options = val.options;
      poll.pollQuestion = val.pollQuestion;
      poll.startsAt = val.startsAt;
      poll.type = val.type;
      this.setState({
        showEditPostBox: false,
        poll: poll,
      });
      this.props.onAlert('Sucessfully edited poll');
    } catch (error) {
      console.log(error);
    }
  }

  async setLikeByUser(val) {
    try {
      this.setState({
        likedByUser: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  updatePublishLaterDate(val) {
    try {
      this.setState({
        publishAt: val,
      });
    } catch (error) {
      console.log(error);
    }
  }
  changeShareWithAll(val) {
    try {
      this.setState({
        isSharedWithAll: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async changeShareWithAdmin(val) {
    try {
      await this.setState({
        isSharedWithAdmin: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeShareWithLearner(val) {
    try {
      this.setState({
        isSharedWithLearner: val,
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeOnlySharedWidth(val) {
    try {
      this.setState({
        onlySharedWith: val,
      });
    } catch (error) {
      console.log(error);
    }
  }
  showConfirmBox(val) {
    try {
      this.setState({
        confirmMessage: val,
      });
    } catch (error) {
      console.log(error);
    }
  }
  extractContent() {
    try {
      this.setState({
        extractContent: true,
        hasReadMore: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  checkImgOrVideoExistence = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;

    const images = div.querySelectorAll('img');
    const videos = div.querySelectorAll('video');

    return images.length > 0 || videos.length > 0;
  };

  textLength = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const paragraphs = doc.querySelectorAll('p');
    const combinedText = Array.from(paragraphs)
      .map((p) => p.textContent)
      .join(' ');
    const tLength = combinedText.length;
    return tLength;
  };

  render() {
    const {
      img,
      isPinned,
      pinnedBy,
      confirmMessage,
      updatedAt,
      from,
      title,
      id,
      content,
      galleryType,
      gallery,
      attachments,
      newsFeedID,
      creatorName,
      isSchedule,
      isPollVoted,
      isCommentable,
      likedByUser,
      totalLikes,
      medias,
      publishAt,
      isSharedWithAdmin,
      isSharedWithAll,
      isSharedWithLearner,
      onlySharedWith,
      creatorId,
      top,
      extractContent,
      hasReadMore,
      isShowMore,
      adBottomDatas,
      videoThumbnail,
    } = this.state;

    const hasImagesOrVideos = this.checkImgOrVideoExistence(content);

    return (
      <>
        {this.state.confirmBoxStatus ? (
          <ConfirmBox
            msg={confirmMessage}
            onOK={() => this.deletePoll()}
            onCancel={() => this.setConfirmBoxStatus(false)}
          />
        ) : (
          ''
        )}
        {isSchedule ? (
          <div className="date">{this.convertDate(publishAt)}</div>
        ) : (
          ''
        )}

        {this.state.showEditPostBox ? (
          <CreatePost
            title={title}
            description={content}
            medias={medias}
            attachments={this.state.attachments}
            mode={this.state.mode}
            id={id}
            creatorId={creatorId}
            publishAt={publishAt}
            newsFeedID={newsFeedID}
            onUpdated={(val) => this.updatePost(val)}
            onPublishLaterDate={(val) => this.updatePublishLaterDate(val)}
            onLoading={(val) => this.props.onLoading(val)}
            onDeleteItem={(item) => this.props.onDeleteItem(item)}
            onChangeMode={(val) => this.changeMode(val)}
            onAlert={(data) => this.props.onAlert(data)}
            onOK={() => this.props.onOK()}
            onAddPost={(obj) => this.props.onAddPost(obj)}
            onEditedPoll={(val) => this.editedPoll(val)}
            poll={this.state.poll}
            comments={this.state.comments}
            isSchedule={isSchedule ? isSchedule : false}
            isSharedWithAdmin={isSharedWithAdmin}
            isSharedWithAll={isSharedWithAll}
            isSharedWithLearner={isSharedWithLearner}
            onlySharedWith={onlySharedWith}
            videoThumbnail={videoThumbnail}
          />
        ) : (
          ''
        )}

        {!this.state.showEditPostBox && id != null ? (
          <section
            className={`post ${
              isPinned && pinnedBy === 'admin' ? 'pinned' : ''
            }`}
            ref={this.postRef}
          >
            <PostHeader
              isPinned={isPinned}
              img={img}
              updatedAt={updatedAt}
              from={from}
              title={title}
              id={id}
              isSchedule={isSchedule}
              onShowEditPost={(id) => this.showEditBox(id)}
              creatorName={creatorName}
              onAlert={(data) => this.props.onAlert(data)}
              onOK={() => this.props.onOK()}
              isCommentable={isCommentable}
              onCrawlPostList={() => this.props.onCrawlPostList()}
              onRemovePost={(id) => this.props.onRemovePost(id)}
              pinnedBy={this.props.pinnedBy}
            />
            {content ? (
              <div ref={this.contRef} className="cont fullHeight">
                {hasImagesOrVideos ? (
                  // ? renderHTML(content)
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                ) : this.textLength(content) > 350 && !isShowMore ? (
                  // renderHTML(`${content.substring(0, 350)}...`)
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${content.substring(0, 350)}...`,
                    }}
                  />
                ) : (
                  // renderHTML(content)
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </div>
            ) : (
              ''
            )}
            {!hasImagesOrVideos &&
            this.textLength(content) > 350 &&
            !isShowMore ? (
              <button
                className="readmorebtn"
                onClick={() =>
                  this.setState((prevState) => ({
                    isShowMore: !prevState.isShowMore,
                  }))
                }
              >
                Read more
              </button>
            ) : (
              ''
            )}
            {this.state.showSlider ? (
              <Slider
                onClose={() => this.closeSlider()}
                index={this.state.sliderIndex}
                items={gallery}
                top={top}
              />
            ) : (
              <Gallery
                galleryType={galleryType}
                gallery={gallery}
                onShowBigImg={(e, obj) => this.showLargeImg(gallery, obj)}
                top={top}
              />
            )}
            {attachments && attachments.length > 0 ? (
              <AttachmentList attachments={attachments} />
            ) : (
              ''
            )}
            {this.state.poll && this.state.poll.startsAt ? (
              new Date(this.state.poll.startsAt) < new Date() &&
              new Date(this.state.poll.closesAt) > new Date() ? (
                <PostPoll isPollVoted={isPollVoted} data={this.state.poll} />
              ) : new Date(this.state.poll.startsAt) < new Date() &&
                this.state.poll.hasOwnProperty('closesAt') === false ? (
                <PostPoll isPollVoted={isPollVoted} data={this.state.poll} />
              ) : new Date(this.state.poll.closesAt) < new Date() ? (
                <PollResult
                  voted={isPollVoted}
                  options={this.state.poll.options}
                  pollQuestion={this.state.poll.pollQuestion}
                  totalParticipations={this.state.poll.totalParticipations}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )}

            <ShortInfo
              totalLikes={totalLikes}
              likedByUser={this.props.likedByUser}
              id={id}
            />

            {isSchedule === false ? (
              <ActionButtons
                onCommentStatus={(val) => this.setCommentStatus(val)}
                messageData={this.props.messageData}
                newsFeedClientID={id}
                totalComments={this.state.totalComments}
                isCommentable={isCommentable}
                likedByUser={this.props.likedByUser}
                onLikedByUser={(val) => this.setLikeByUser(val)}
                isRevoked={this.props.isRevoked}
              />
            ) : (
              ''
            )}

            {this.state.showCommentBox && !this.state.isSchedule ? (
              <CommentBox
                comments={this.state.comments}
                newsFeedID={this.state.newsFeedID}
                totalLikes={this.state.totalLikes}
                newsFeedClientID={this.state.id}
                onCrawlPostList={() => this.props.onCrawlPostList()}
                onLoading={(val) => this.props.onLoading(val)}
                onAddComment={() => this.addComment()}
                onAlert={(data) => this.props.onAlert(data)}
                onConfirm={(data) => this.showConfirmBox(data)}
                meta={this.state.meta}
              />
            ) : (
              ''
            )}
          </section>
        ) : (
          ''
        )}
        {adBottomDatas && (
          <UncontrolledAlert
            color="info"
            className="p-0 m-0 mb-3"
            key={adBottomDatas._id}
            isOpen={!this.props.dismissedAlertIds.includes(adBottomDatas._id)}
            toggle={() => {
              this.props.onDismiss(adBottomDatas._id);
              this.props.handleAdClose(adBottomDatas);
            }}
            fade={false}
          >
            <section className="post m-0 px-1">
              <header className="mt-0">
                <div className="leftSide">
                  <img
                    className="tinyimg"
                    src={adBottomDatas.adThumbUrl || adBottomDatas.adPostUrl}
                    alt="no name"
                  />

                  <div className="shortUserInfo">
                    <h3>
                      {adBottomDatas.title} -{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-badge-ad ml-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z" />
                        <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                      </svg>{' '}
                    </h3>
                  </div>
                </div>
                {adBottomDatas.embedLink && (
                  <div className="rightSide mr-5">
                    <a
                      href={adBottomDatas.embedLink}
                      target="_blank"
                      title="Click to view"
                      onClick={() => this.props.handleAdClick(adBottomDatas)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        fill="currentColor"
                        className="bi bi-box-arrow-up-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                        />
                      </svg>
                    </a>
                  </div>
                )}
              </header>
              <div
                className="gallery singlePortait ads"
                // onClick={() => this.props.handleAdClick(adBottomDatas)}
              >
                <div className="firstTile">
                  {adBottomDatas.fileType === 'Video' ? (
                    <>
                      <ReactPlayer
                        url={adBottomDatas.adPostUrl || adBottomDatas.mainUrl}
                        playing={true}
                        muted={true}
                        loop={true}
                        controls={true}
                        width="100%"
                        height="100%"
                        className="video-object-fit-contain"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          adBottomDatas.adPostUrl || adBottomDatas.adThumbUrl
                        }
                        alt={adBottomDatas.title}
                        title={adBottomDatas.title}
                      />
                      <img
                        src={
                          adBottomDatas.adPostUrl || adBottomDatas.adThumbUrl
                        }
                        className="bgImage"
                        alt={adBottomDatas.title}
                        title={adBottomDatas.title}
                      />
                    </>
                  )}
                </div>
              </div>
            </section>
          </UncontrolledAlert>
        )}
      </>
    );
  }
}

export default React.memo(SinglePost);
