import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import { UncontrolledAlert } from 'reactstrap';
import SinglePost from './post/SinglePost';
import TimeAgo from 'javascript-time-ago';
import { API } from './api/API.js';
import { Global } from './api/Global.js';
import en from 'javascript-time-ago/locale/en';

/* **************************************************************************** */
/*                           POST LIST                                          */
/* **************************************************************************** */
/*
    Events
    ========
    onCrawlPostList() | required

    Props
    ======
    listPostAgain : boolean | required
   
*/

function detectDeviceType() {
  const userAgent = navigator.userAgent;
  if (
    /iPad|Android|webOS|BlackBerry|Windows Phone|Kindle|Tablet|Silk|PlayBook/i.test(
      userAgent,
    )
  ) {
    return 'Tablet';
  } else if (/Mobile|iPhone|iPod|IEMobile|Opera Mini/i.test(userAgent)) {
    return 'Mobile';
  } else {
    return 'Desktop';
  }
}
const deviceType = detectDeviceType();

class PostList extends PureComponent {
  constructor(props) {
    try {
      super(props);
      // Add locale-specific relative date/time formatting rules.
      TimeAgo.addLocale(en);

      this.state = {
        posts: [],
        fetchNewsStatus: 'finished',
        showConfirmBox: false,
        removeID: null,
        showCommentBox: false,
        isLoading: false,
        page: 1,
        meta: null,
        isStateModifed: false,
        userInfo: null,
        adsData: [],
        adsBottomData: [],
        userToken: null,
        adsLoadedDateTime: null,
        dismissedAlertIds: [],
        isRevoked: null,
      };
      this.onDismiss = this.onDismiss.bind(this);
    } catch (err) {
      console.log(err);
    }
  }

  onDismiss(id) {
    this.setState((prevState) => ({
      dismissedAlertIds: [...prevState.dismissedAlertIds, id],
    }));
  }

  async fetchUserInfo() {
    return API.userInfo()
      .then((response) => {
        return {
          name: response.name ? response.name : 'Olive',
          email: response.email ? response.email : '',
          profilePic: response.profilePic
            ? response.profilePic
            : '/img/logo.png',
          orgIcon: response.orgIcon ? response.orgIcon : '/img/logo.png',
          orgName: response.orgName ? response.orgName : 'Olive',
          creator_id: response.creator_id.toString(),
        };
      })
      .catch((error) => console.log(error));
  }
  async componentDidUpdate() {
    try {
      if (!this.state.isStateModifed) {
        await this.setState(
          {
            posts: this.props.postList,
            meta: this.props.meta,
          },
          () => {
            // console.log(this.state);
          },
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
  setLoading(val) {
    try {
      this.setState({
        isLoading: val,
      });
    } catch (err) {
      console.log(err);
    }
  }
  removeItemAll(arr, value) {
    // If got response then fetchc News
    this.setState({
      showConfirmBox: false,
      removeID: null,
    });
    try {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    } catch (error) {
      console.log(error);
    }
  }

  async addNewsFeedAtFirst(item) {
    let posts = [...this.state.posts];
    posts.unshift(item);
    await this.setState({
      posts: posts,
    });
  }
  changeUpdateNewsFeed() {
    try {
      this.fetchNews().then((result) => {
        this.setState({
          posts: result,
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  onDeleteComment() {
    try {
      Global.socket.on('deleteComment', async (response) => {
        if (response && response.message === 'success') {
          let posts = [...this.state.posts];
          let newsFeedObj = posts.find(
            (item) => item.id === response.data.newsFeedClient,
          );
          let index = posts.indexOf(newsFeedObj);
          posts[index].totalComments -= 1;
          this.setState({
            posts: posts,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  listenCommentAction() {
    Global.socket.on('comment', async (response) => {
      console.log(response);
      let msg = response.data;
      let posts = [...this.state.posts];
      let obj = posts.find((item) => item.id === msg.newsFeedClient);
      let index = posts.indexOf(obj);
      // Comments

      if (
        posts[index].comments === undefined ||
        posts[index].comments === null
      ) {
        posts[index].comments = [];
      }
      let comments = posts[index].comments;

      let comment = {
        _id: msg._id,
        commentDescription: msg.commentDescription,
        createdAt: msg.createdAt,
        creator: msg.creator,
        isActive: msg.isActive,
        isDeleted: msg.isDeleted,
        numberOfLikes: msg.numberOfLikes,
        numberOfReplies: msg.numberOfReplies,
        organisationId: msg.organisationId,
        totalComment: msg.totalComment,
        updatedAt: msg.updatedAt,
        userId: msg.userId,
      };

      comments.unshift(comment);

      let arr = window.location.href.split('?')[1].split('&');
      let organisationToken = null;
      const { organisationId } = msg;

      arr.map((item) => {
        if (item.includes('organisationId')) {
          let splitToken = item.split('organisationId=');
          organisationToken = splitToken[1];
        }
      });
      if (organisationId === Global.decryptToken(organisationToken)) {
        posts[index].totalComments = response.data.totalComment;
        await this.setState(
          {
            posts: posts,
          },
          () => {
            this.props.onLoading(false);
          },
        );
      }
    });
  }
  listenLikeAction() {
    Global.socket.on('like', (msg) => {
      let posts = [...this.state.posts];
      let obj = posts.find((item) => item.id === msg.data.newsFeedClient);
      let index = posts.indexOf(obj);
      if (msg.message === 'success') {
        posts[index].totalLikes = msg.data.totalLike;

        posts[index].likedByUser = msg.data.likedUsers.includes(
          this.state.userInfo?.creator_id,
        )
          ? 1
          : 0;
      }

      const { organisationId } = msg?.data;
      let arr = window.location.href.split('?')[1].split('&');
      let organisationToken = null;
      arr.map((item) => {
        if (item.includes('organisationId')) {
          let splitToken = item.split('organisationId=');
          organisationToken = splitToken[1];
        }
      });
      if (organisationId === Global.decryptToken(organisationToken)) {
        this.setState(
          {
            posts: posts,
          },
          () => {},
        );
      }
    });
  }
  async componentDidMount() {
    const adsLoadedDateTime = new Date();
    this.setState({ adsLoadedDateTime });

    try {
      this.fetchAds();
      this.fetchBottomAds();
      const searchParams = new URLSearchParams(window.location.search);
      if (Global.socket) {
        // Listening Like Socket
        this.listenLikeAction();

        this.listenCommentAction();
        this.onDeleteComment();
      }

      this.setState({
        posts: this.props.postList,
        meta: this.props.meta,
        userToken: searchParams.get('userToken'),
      });
    } catch (error) {
      console.log(error);
    }
  }
  setCommentStatus(val) {
    try {
      this.setState({
        showCommentBox: val,
      });
    } catch (error) {
      console.log(error);
    }
  }
  async removePost(id) {
    await API.removePost({ id: id })
      .catch((error) => {
        console.log(error);
      })
      .then(async (response) => {
        let posts = [...this.state.posts];
        let obj = posts.find((item) => item.id === id);
        let index = posts.indexOf(obj);

        posts.splice(index, 1);

        await this.setState({
          posts: posts,
          isStateModifed: true,
        });
      });
  }
  async updateItem(obj) {
    try {
      let posts = [...this.state.posts];

      let item = posts.find((item) => item.id === obj.newsFeedClientId);
      if (item) {
        let index = posts.indexOf(item);

        posts[index].title = obj.title;
        posts[index].content = obj.content;
        posts[index].gallery = obj.gallery;
        posts[index].galleryType = obj.galleryType;
        posts[index].attachments = obj.attachments;
        posts[index].combined = [...obj.gallery, ...obj.attachments];

        await this.setState({
          posts: posts,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async donotRemovePost() {
    await this.setState({
      showConfirmBox: false,
      removeID: null,
    });
  }

  async showConfirmBox(id) {
    this.setState({
      showConfirmBox: true,
      removeID: id,
    });
  }
  async addPost(obj) {
    let posts = [...this.state.posts];
    posts.unshift(obj);
    await this.setState({
      posts: posts,
    });
  }

  // ===========================================================================
  // Parse URL parameters to get platformId
  getPlatformIdFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('platformId');
  }

  // GET ADS
  async fetchAds() {
    try {
      const platformId = this.getPlatformIdFromUrl();
      const userInfo = await this.fetchUserInfo();
      if (userInfo) {
        this.setState({
          userInfo,
        });
      }
      if (platformId) {
        const response = await API.getAdsPageData(platformId, 'News Feed');

        this.setState({
          adsData: response.data,
        });

        response.data.map((item) => {
          this.viewAds(item);
        });
      } else {
        console.log('Platform ID not found in the URL.');
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  // GET ADS Bottom
  async fetchBottomAds() {
    try {
      const platformId = this.getPlatformIdFromUrl();

      if (platformId) {
        const response1 = API.getAdsPageData(platformId, 'General News');

        const userInfo1 = this.fetchUserInfo();
        const [response, userInfo] = await Promise.all([response1, userInfo1]);

        this.setState({
          adsBottomData: response.data,
          isLoading: false,
          userInfo,
        });

        response.data.map((item) => {
          this.viewAds(item);
        });
      } else {
        console.log('Platform ID not found in the URL.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  // POST ADS
  handleAdClose = (adInfo) => {
    const closedAt = new Date();
    const requestData = this.prepareAdData({
      adInfo: adInfo,
      restData: {
        closedAt: closedAt,
        clickedOrNot: 'Not Clicked',
      },
    });

    this.postAd(requestData);

    this.onDismiss();
  };

  handleAdClick = (adInfo) => {
    const clickDate = new Date();
    const requestData = this.prepareAdData({
      adInfo: adInfo,
      restData: {
        clickedDateTime: clickDate,
        clickedOrNot: 'Clicked',
      },
    });

    this.postAd(requestData);
  };

  viewAds = (adInfo) => {
    const requestData = this.prepareAdData({
      adInfo: adInfo,
      restData: {
        clickedOrNot: 'Not Clicked',
      },
    });

    this.postAd(requestData);
  };

  prepareAdData = ({ adInfo, restData = {} }) => {
    const clubId = adInfo._id;
    const clubName = adInfo.club;
    const platform = adInfo.platforms;
    const visitorName = this.state.userInfo.name;
    const email = this.state.userInfo.email;

    return {
      advertisement: clubId,
      club: clubName,
      clubName: clubName,
      platform: platform,
      visitorName: visitorName,
      visitorEmail: email,
      clickedDevice: detectDeviceType(),
      ...restData,
    };
  };

  postAd = async (requestData) => {
    try {
      const platformId = this.getPlatformIdFromUrl();

      if (platformId) {
        const response = await API.postAdsPageData(platformId, requestData);
        console.log('New Ad Post:', response);
      } else {
        console.log('Platform ID not found in the URL.');
      }
    } catch (error) {
      console.log('Error creating ad:', error);
    }
  };

  render() {
    const { adsData, posts } = this.state;

    return (
      <>
        {adsData.length > 0 ? (
          <>
            {adsData.map((adInfo, index) => (
              <UncontrolledAlert
                color="info"
                className="p-0 m-0 mb-3"
                key={adInfo._id}
                isOpen={!this.state.dismissedAlertIds.includes(adInfo._id)}
                toggle={() => {
                  this.onDismiss(adInfo._id);
                  this.handleAdClose(adInfo);
                }}
                fade={false}
              >
                <section className="post m-0 px-1">
                  <header className="mt-0">
                    <div className="leftSide">
                      <img
                        className="tinyimg"
                        src={adInfo.adThumbUrl || adInfo.adPostUrl}
                        alt="no name"
                      />

                      <div className="shortUserInfo">
                        <h3>
                          {adInfo.title} -{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-badge-ad ml-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z" />
                            <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" />
                          </svg>{' '}
                        </h3>
                      </div>
                    </div>
                    {adInfo.embedLink && (
                      <div className="rightSide mr-5">
                        <a
                          href={adInfo.embedLink}
                          target="_blank"
                          title="Click to view"
                          onClick={() => this.handleAdClick(adInfo)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                  </header>
                  <div className="gallery singlePortait ads">
                    <div className="firstTile">
                      {adInfo.fileType === 'Video' ? (
                        <>
                          <ReactPlayer
                            url={adInfo.adPostUrl || adInfo.mainUrl}
                            playing={true}
                            muted={true}
                            loop={true}
                            controls={true}
                            width="100%"
                            height="100%"
                            className="video-object-fit-contain"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={adInfo.adPostUrl || adInfo.adThumbUrl}
                            alt={adInfo.title}
                            title={adInfo.title}
                          />
                          <img
                            src={adInfo.adPostUrl || adInfo.adThumbUrl}
                            className="bgImage"
                            alt={adInfo.title}
                            title={adInfo.title}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </UncontrolledAlert>
            ))}
          </>
        ) : null}

        {posts && posts.length > 0
          ? posts.map((item) => (
              <SinglePost
                isRevoked={this.state.isRevoked}
                key={item.id}
                img={item.img}
                pinnedBy={item.pinnedBy}
                isPinned={item.isPinned}
                id={item.id}
                title={item.title}
                updatedAt={item.publishedAt}
                from={item.from}
                creatorId={item.creatorId}
                content={item.content}
                gallery={item.gallery}
                combined={item.combined}
                galleryType={item.galleryType}
                attachments={item.attachments}
                medias={item.medias}
                newsFeedID={item.newsFeedID}
                creatorName="no name"
                likedByUser={item.likedByUser}
                comments={[]}
                totalComments={item.totalComments}
                totalLikes={item.totalLikes}
                isSchedule={false}
                poll={item.poll}
                isPollVoted={item.isPollVoted}
                isCommentable={item.isCommentable}
                publishAt={item.publishAt}
                isSharedWithAdmin={
                  item.isSharedWithAdmin ? item.isSharedWithAdmin : null
                }
                isSharedWithAll={
                  item.isSharedWithAll ? item.isSharedWithAll : null
                }
                isSharedWithLearner={
                  item.isSharedWithLearner ? item.isSharedWithLearner : null
                }
                onlySharedWith={
                  item.onlySharedWith ? item.onlySharedWith : null
                }
                onUpdated={() => this.changeUpdateNewsFeed()}
                onCrawlPostList={() => this.props.onCrawlPostList()}
                onLoading={(val) => this.props.onLoading(val)}
                onDeleteItem={(item) => this.props.onDeleteItem(item)}
                onAlert={(data) => this.props.onAlert(data)}
                onRemovePost={(id) => this.removePost(id)}
                onUpdateItem={(item) => this.updateItem(item)}
                onAddPost={(obj) => this.addPost(obj)}
                adBottomDatas={item.ad}
                handleAdClick={(data) => this.handleAdClick(data)}
                onDismiss={(data) => this.onDismiss(data)}
                handleAdClose={(data) => this.handleAdClose(data)}
                dismissedAlertIds={this.state.dismissedAlertIds}
              />
            ))
          : ''}

        <p className="lastdiv" ref={this.props.postRef}></p>
      </>
    );
  }
}

export default React.memo(PostList);
