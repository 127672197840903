import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CarouselItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      optionStatus: false,
      aspectRatio: null,
      videoUrl: [],
    };
    this.videoRef = React.createRef();
  }

  setOption(val) {
    this.setState({
      optionStatus: val,
    });
  }

  deleteOption() {
    return this.state.optionStatus ? (
      <button onClick={() => this.props.onDeleteItem(this.props.item)}>
        <img src="/img/close.svg" alt="close button" />
      </button>
    ) : null;
  }

  truncateFileName(filename, length = 20) {
    return filename.length > length
      ? `${filename.slice(0, length)}...`
      : filename;
  }

  imageSrc() {
    const { thumbnail = [] } = this.props;

    const urls = [];
    if (thumbnail.length === 0) {
      return [];
    }

    const maxIndex = Math.max(...thumbnail.map((t) => t?.index ?? 0), 0);

    for (let i = 0; i <= maxIndex; i++) {
      const thumbnailItem = thumbnail.find((t) => t?.index === i);
      if (thumbnailItem) {
        urls[i] = thumbnailItem.url
          ? thumbnailItem.url
          : thumbnailItem.file
          ? URL.createObjectURL(thumbnailItem.file)
          : undefined;
      } else {
        urls[i] = undefined;
      }
    }
    return urls;
  }

  deleteOptionForThumb = (index) => (
    <button onClick={() => this.props.removeThumbnail(index)}>
      <img src="/img/close.svg" alt="Delete" className="close" />
    </button>
  );

  componentWillUnmount() {
    const urls = this.imageSrc();
    urls.forEach((url) => {
      if (url) URL.revokeObjectURL(url);
    });
  }

  componentDidUpdate() {
    const { thumbnail, index, updateThumbnailError, mode, item } = this.props;
    const hasThumbnailError =
      mode === 'edit' && (!thumbnail || !thumbnail[index]);

    if (hasThumbnailError && item.type === 'video') {
      updateThumbnailError('Thumbnail is necessary');
    }
    // if (prevState.videoUrl !== this.state.videoUrl) {

    this.setState({
      videoUrl: this.props.item.videoUrl,
    });
    this.handleVideoMetadata();
    // }
  }

  getGCD(a, b) {
    while (b) {
      [a, b] = [b, a % b];
    }
    return a;
  }

  handleVideoMetadata = () => {
    if (this.videoRef.current) {
      const { videoWidth, videoHeight } = this.videoRef.current;
      const gcd = this.getGCD(videoWidth, videoHeight);
      const simplifiedWidth = videoWidth / gcd;
      const simplifiedHeight = videoHeight / gcd;
      this.setState({
        aspectRatio: `${simplifiedWidth}:${simplifiedHeight}`,
      });
    }
  };

  render() {
    const { item, index, handleImageUpload, mode, thumbnailError } = this.props;
    const { aspectRatio, videoUrl } = this.state;
    const thumbnailUrls = this.imageSrc();

    return (
      <div className="content">
        {item.type === 'image' ? (
          <div
            className="inside-image"
            onMouseEnter={() => this.setOption(true)}
            onMouseLeave={() => this.setOption(false)}
          >
            <img src={item.src} alt="slide" />
            {this.deleteOption()}
          </div>
        ) : item.type === 'video' ? (
          <div className="video-div">
            <div
              className="inside-video"
              onMouseEnter={() => this.setOption(true)}
              onMouseLeave={() => this.setOption(false)}
            >
              {this.deleteOption()}
              <div className="playBtn">
                <img src="/img/play-light-2-pt.svg" alt="play button" />
              </div>
              <video
                ref={this.videoRef}
                onLoadedMetadata={this.handleVideoMetadata}
                controls
              >
                <source src={item.src} type="video/mp4" />
              </video>
            </div>
            <div className="inputField-div">
              <p>
                {`Recommended Thumbnail Image Aspect Ratio ${
                  mode === 'edit'
                    ? item.width && item.height
                      ? `${item.width}:${item.height}`
                      : aspectRatio
                    : aspectRatio
                }`}
              </p>

              {!thumbnailUrls[index] ? (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, index)}
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    style={{ display: 'none' }}
                  />
                  <p
                    className="inputField"
                    onClick={() => this.fileInput.click()}
                  >
                    Upload Thumbnail
                  </p>

                  {mode === 'edit' && !thumbnailUrls[index] && (
                    <p style={{ color: 'red' }}>{thumbnailError}</p>
                  )}
                </>
              ) : (
                <div className="thumbImg">
                  <img
                    accept="image/*"
                    src={thumbnailUrls[index]}
                    className="inputImage"
                    data-type="image"
                    alt="thumbnail preview"
                  />
                  {this.deleteOptionForThumb(index)}
                </div>
              )}
            </div>
          </div>
        ) : item.type === 'attachment' ? (
          <div className="attachment-div">
            <div
              className="inside-attachment"
              onMouseEnter={() => this.setOption(true)}
              onMouseLeave={() => this.setOption(false)}
            >
              {this.deleteOption()}
              <a
                href={item.url}
                className="fileLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/document.svg"
                  className="doc"
                  alt="document icon"
                />
                <strong className="attachment-name">
                  {this.truncateFileName(item.name, 10)}
                </strong>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

CarouselItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  thumbnail: PropTypes.array,
  onDeleteItem: PropTypes.func.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
};

export default CarouselItem;
